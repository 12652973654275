#screenaLoader_0 {
  --_dotSize: 15px;
  --_dotMargin: 10px;
  .loader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }

  .dot {
    display: inline-block;
    width: var(--_dotSize);
    height: var(--_dotSize);
    margin-right: var(--_dotMargin);
    border-radius: 50%;
    -webkit-animation: dot-pulse2 1.5s ease-in-out infinite;
    animation: dot-pulse2 1.5s ease-in-out infinite;
  }

  .dot-1 {
    background-color: #23b4b4;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .dot-2 {
    background-color: #aeaeae;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  .dot-3 {
    background-color: #23b4b4;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  @keyframes dot-pulse2 {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
      opacity: 0.5;
    }

    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
      opacity: 0.5;
    }
  }
}

#screenaLoader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .loader {
    width: 48px;
    // height: 48px;
    aspect-ratio: 1/1;
    border: 5px solid var(--color-gray-new);
    border-bottom-color: var(--color-azure);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
