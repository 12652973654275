$fontName: 'Inter';

@font-face {
  font-family: $fontName;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: local($fontName), local('#{$fontName}-Thin'), url('./#{$fontName}-Thin.ttf') format('truetype');
}
@font-face {
  font-family: $fontName;
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: local($fontName), local('#{$fontName}-ExtraLight'), url('./#{$fontName}-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: $fontName;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local($fontName), local('#{$fontName}-Light'), url('./#{$fontName}-Light.ttf') format('truetype');
}
@font-face {
  font-family: $fontName;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local($fontName), local('#{$fontName}-Regular'), url('./#{$fontName}-Regular.ttf') format('truetype');
}
@font-face {
  font-family: $fontName;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local($fontName), local('#{$fontName}-Medium'), url('./#{$fontName}-Medium.ttf') format('truetype');
}
@font-face {
  font-family: $fontName;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local($fontName), local('#{$fontName}-SemiBold'), url('./#{$fontName}-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: $fontName;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local($fontName), local('#{$fontName}-Bold'), url('./#{$fontName}-Bold.ttf') format('truetype');
}
@font-face {
  font-family: $fontName;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: local($fontName), local('#{$fontName}-ExtraBold'), url('./#{$fontName}-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: $fontName;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local($fontName), local('#{$fontName}-Black'), url('./#{$fontName}-Black.ttf') format('truetype');
}
