@import 'media/fonts/Inter/Inter.scss';
html,
body {
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;

  scroll-behavior: smooth;
}

:root {
  --color-white: #ffffff;
  --color-lightgray: #fafafa;
  --color-lightgray2: #f0f0f0;
  --color-lightgray3: #e5e5e5;
  --color-lightgray4: #cfd1d2;
  --color-lightgray5: #a7a7a7;
  --color-lightgray6: #f7f8fa;

  --color-grayHeaderBorder: #e3e4e5;

  --color-gray-new: #545352;

  --color-gray1: #999999;
  --color-gray2: #666666;
  --color-darkgray: #333333;
  --color-black: #000000;

  --color-azure: #2fb6b4;
  --color-azurelight: #cae8e8;
  --color-azureverylight: #edf8f8;

  --color-fushia: #ed1f55;
  --color-fushialight: #ffa0a0;
  --color-fushiaverylight: #ffdbdb;

  --color-yellowpale: #faf6d7;

  --color-green: #5ebe70;
  --color-blue: #2c3890;
  --color-yellow: #fcd059;
  --color-orange: #ed8e1f;
  --color-red: #ff0000;

  --main-inline-padding: 60px;
  --header-height: min(10vh, 120px);
  --min-header-height: 85px;

  --fs-6: 0.375rem;
  --fs-8: 0.5rem;
  --fs-10: 0.625rem;
  --fs-11: 0.6875rem;
  --fs-12: 0.75rem;
  --fs-14: 0.875rem;
  --fs-16: 1rem;
  --fs-20: 1.25rem;
  --fs-28: 1.75rem;
}

img {
  max-width: 100%;
  display: block;

  &.cover {
    width: 100%;
    object-fit: cover;
    object-position: center;

    &.top {
      object-position: top;
    }
  }
}

a[target='_blank'] {
  color: var(--color-azure);
  word-break: break-all;
}
.button-box-shadow {
  // box-shadow: 0px 0px 6px #0000001a;
  box-shadow: rgba(51, 51, 51, 0.08) 0px 0px 4px 0px;

  &:hover:not(.no-hover) {
    box-shadow: rgba(51, 51, 51, 0.18) 0px 0px 6px 0px;
    // border: 5px solid red;
  }
}

/* utilities*/
.disable-text-fill {
  -webkit-text-fill-color: unset;
}

.restricted-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines, 3);
  overflow: hidden;
}

.user-avatar {
  border-radius: 50%;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
}
/* links */
a,
a.MuiLink-root {
  text-decoration: none;
}
/* colors */
.bckg-lightgray {
  background-color: var(--color-lightgray);
}

.bordered {
  border: 1px solid var(--color-grayHeaderBorder);
}
.bordered-shadow {
  box-shadow: inset 0px 0px 0px 1px var(--color-grayHeaderBorder);
}

.rounded-border {
  border-radius: 5px;
}

/* logo */
.in-page-logo {
  width: 100%;
  max-width: 250px;
  height: auto;
  max-height: 100px;
  object-fit: contain;
  object-position: center;
}

.logo-menu {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
  display: block;
}

/* menu */
$appMenuAnimationDuration: 225ms;
.app-menu {
  $menuClosedWidth: 80px;
  $menuPadding: 0.5rem;
  $imagePadding: 16px;
  $chevronWidth: 1rem;

  .button-trigger {
    rotate: 0deg;
    transition: rotate $appMenuAnimationDuration linear;
  }

  label {
    cursor: pointer;
  }
  span {
    font-weight: inherit;
    font-size: inherit;
    vertical-align: middle;
  }
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  $headerIconSize: 1rem;
  $headerIconMargin: 0.4rem;
  $linksMarginBottom: 0.5rem;
  $navLinkPaddingBlock: 2px;
  $navLinkFontSize: 0.875rem;
  $navLinkLineHeight: 1.5;

  .menu-item-container {
    padding-left: 32px;
    padding-right: 24px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-right: 1px solid var(--color-darkgray);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    // navigation links
    .header-link {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 0.75rem;
      margin-bottom: $linksMarginBottom;
      display: block;

      span {
        opacity: 0;
        animation: show ($appMenuAnimationDuration - 150ms) 150ms linear forwards;
      }

      .MuiSvgIcon-root {
        vertical-align: middle;
        font-size: $headerIconSize;
        margin-right: $headerIconMargin;
      }
    }

    .nav-link {
      font-size: $navLinkFontSize;
      line-height: $navLinkLineHeight;
      color: var(--color-lightgray3);
      margin-left: calc(#{$headerIconSize} + #{$headerIconMargin});
      // padding-left: 5px;
      padding-inline: 5px;
      padding-block: $navLinkPaddingBlock;
      border-radius: 5px;
      margin-bottom: $linksMarginBottom;
      text-transform: capitalize;

      display: flex;
      align-items: flex-start;

      &.active {
        background-color: var(--color-azure);
      }

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      div.has-unread {
        margin-top: 3px;
        $unread-size: 6px;
        width: $unread-size;
        height: $unread-size;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: var(--color-fushia);
      }
    }

    .toggle-label {
      color: white;
      font-size: 0.75rem;
      display: inline-block;
      vertical-align: middle;
      padding-left: 10px;

      svg {
        font-size: 0.75rem;
      }

      &.is-active {
        display: none;
        svg {
          rotate: 90deg;
        }
      }
    }
    .sub-toggle-label {
      @extend .nav-link;
      display: block;
      padding: 0;

      &.active {
        background-color: transparent;
        .label-inner {
          background-color: var(--color-azure);
        }
      }
      .label-inner {
        display: inline-block;
        border-radius: 5px;
        padding-inline: 5px;
        padding-block: $navLinkPaddingBlock;
      }
      svg {
        margin-left: 8px;
        vertical-align: middle;
        font-size: 0.75rem;
      }

      &.is-null {
        svg {
          rotate: 180deg;
        }
      }

      &.is-active {
        display: none;
        svg {
          rotate: -90deg;
        }
      }
    }

    input.menu-selector,
    input.sub-menu-selector {
      display: none;
      // display: block;
    }
    .menu-collapse,
    .sub-menu-collapse {
      max-height: 0;
      transition: max-height 250ms linear;
      overflow: hidden;
      opacity: 0;
      animation: show ($appMenuAnimationDuration - 150ms) 150ms linear forwards;
    }

    .sub-menu-collapse {
      padding-left: 1rem;
      .nav-link {
        &.active {
          background-color: transparent;
          font-weight: 600;
        }
      }
    }
    input.menu-selector:checked {
      & + .menu-header {
        label.is-null {
          display: none;
        }
        label.is-active {
          display: inline-block;
        }

        & + .menu-collapse {
          max-height: calc(var(--nb-menu-items) * (2 * #{$navLinkPaddingBlock} + #{$linksMarginBottom} + #{$navLinkLineHeight}*#{$navLinkFontSize}));
        }
      }
    }
    input.sub-menu-selector:checked {
      & ~ label.is-null {
        display: none;
      }
      & ~ label.is-active {
        display: block;
      }

      & ~ .sub-menu-collapse {
        max-height: calc(var(--nb-menu-items) * (2 * #{$navLinkPaddingBlock} + #{$linksMarginBottom} + #{$navLinkLineHeight}*#{$navLinkFontSize}));
      }
    }
  }

  .bottom-menu-button {
    span.text {
      margin-left: $headerIconMargin;
    }
  }
  &.menu-closed {
    .button-trigger {
      rotate: 180deg;
    }
    .logo-menu-closed {
      // $menuPadding: 0.5rem;
      $imagePadding: 8px;
      max-width: calc($menuClosedWidth - 2 * $menuPadding - 2 * $imagePadding - $chevronWidth);
    }

    .menu-item-container {
      padding-right: 32px;
    }
    .menu-header {
      label {
        display: none !important;
      }
    }
    .header-link {
      span {
        display: none !important;
      }
    }

    .menu-collapse,
    .sub-menu-collapse {
      display: none !important;
    }

    .bottom-profile {
      padding-inline: 0;
      min-width: 0;
      .avatar {
        font-size: 32px;
      }
      .pen {
        display: none;
      }
      .username {
        opacity: 0;
      }
    }
    .bottom-menu-button {
      padding-inline: 0;
      justify-content: flex-start;
      span {
        margin-inline: 0;
      }
      span.text {
        display: none;
      }
    }
  }
}

/* label tag*/
div.label-tag,
p.label-tag {
  // font-size: var(--label-tag-font-size, 14px);
  font-size: 0.875rem;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  // border-radius: 9999px;
  line-height: 1.2;
  border-radius: calc(0.875rem * 1.2 + 0.5rem);
  padding-inline: var(--fs-10);
  // padding-block: var(--fs-6);
  padding-block: 3px;
  border: 1px solid var(--color-grayHeaderBorder);
  // color: var(--color-gray2);
  text-align: center;
  p {
    font-size: inherit;
  }
}
/* Gauge */
.circular-gauge {
  --gauge-size: 1rem;
  --gauge-width: 3px;
  --gauge-ratio: 0;
  --gauge-color: var(--color-fushia);

  height: var(--gauge-size);
  aspect-ratio: 1/1;
  background: white;
  // border-radius: 50%;
  // border: var(--gauge-width) solid var(--color-grayHeaderBorder);
  position: relative;
  display: grid;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 10;
    box-sizing: content-box;
    border-radius: 50%;
    border: var(--gauge-width) solid var(--color-grayHeaderBorder);
  }

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 20;
    border-radius: 50%;
    border: var(--gauge-width) solid var(--gauge-color);
    mask-image: conic-gradient(white calc(var(--gauge-ratio) * 360deg), transparent calc(var(--gauge-ratio) * 360deg));
  }
}

/* search card*/
.capitalize {
  text-transform: capitalize;
}
.search-card-content {
  display: flex;

  gap: 1rem;

  .search-card-content-title {
    min-width: var(--title-width, 13ch);
    width: 25%;
    color: var(--color-gray2);
    // white-space: nowrap;
  }
  .search-card-content-text {
    flex: 1;
    min-width: 0;
    color: var(--color-darkgray);
    font-weight: 500;
    // word-break: break-all;
    // white-space: nowrap;
    // overflow-x: hidden;
    // overflow-y: visible;
    // text-overflow: ellipsis;

    // .badge-container{
    //   width
    // }
    .badge-content {
      padding-right: 1.5rem;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.custom-scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--color-lightgray); // #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--color-gray2);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--color-gray1);
  }
}
.custom-scrollbar-horizontal {
  /* height */
  &::-webkit-scrollbar {
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--color-lightgray); // #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--color-gray2);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--color-gray1);
  }
}

.row-container {
  // border: 1px solid green;
  flex-direction: column;
  justify-content: flex-start;
  // transition: height 2500ms linear;
}

/* Calendar */
.filter-date-picker {
  $arrow-size: 20px;
  .MuiPickersCalendarHeader-root {
    margin-top: 5px;
    position: relative;
    padding: 0;

    .MuiPickersCalendarHeader-labelContainer {
      position: absolute;
      width: 100%;
      padding-inline: calc(#{$arrow-size} + 12px);

      .MuiPickersFadeTransitionGroup-root {
        flex: 1;
        text-align: center;
        font-size: 0.8rem;
      }
      .MuiPickersCalendarHeader-switchViewButton {
        display: none;
      }
    }
    .MuiPickersArrowSwitcher-root {
      flex: 1;
      align-items: center;
      .MuiPickersArrowSwitcher-spacer {
        flex: 1;
      }
      .MuiPickersArrowSwitcher-button {
        // font-size: 1.2rem;
        font-size: $arrow-size;
        padding-inline: 5px;
        padding-block: 5px;
        margin: 0;
      }
    }
  }
  .MuiDateCalendar-viewTransitionContainer {
    .MuiDayCalendar-header {
      .MuiDayCalendar-weekDayLabel {
        height: auto;
        padding-block: 5px;
        margin-inline: 0;
      }
    }
    .MuiDayCalendar-slideTransition {
      min-height: 190px;
    }
  }
  .MuiDayCalendar-monthContainer {
    .MuiDayCalendar-weekContainer {
      margin-block: 0;

      .MuiPickersDay-dayWithMargin {
        height: auto;
        aspect-ratio: 1/1;
        margin-inline: 0;
        border-radius: 0;
      }
      button.MuiPickersDay-dayWithMargin {
        border: none;
        border-right: 1px solid var(--color-grayHeaderBorder);
        border-bottom: 1px solid var(--color-grayHeaderBorder);

        border-radius: 0;
        // box-shadow: 0px 0px 0 1px var(--color-grayHeaderBorder);

        // &:last-of-type {
        //   border-right: 1px solid var(--color-grayHeaderBorder);
        // }

        &.Mui-selected {
          background-color: var(--color-darkgray);
          color: white;
        }
      }

      &:first-of-type {
        button.MuiPickersDay-dayWithMargin {
          border-top: 1px solid var(--color-grayHeaderBorder);
        }
        div.MuiPickersDay-dayWithMargin {
          border-bottom: 1px solid var(--color-grayHeaderBorder);
          opacity: 1;

          &:last-child {
            border-right: 1px solid var(--color-grayHeaderBorder);
          }
          &:last-of-type {
            border-right: 1px solid var(--color-grayHeaderBorder);
          }
        }
        button.MuiPickersDay-dayWithMargin:first-child {
          border-left: 1px solid var(--color-grayHeaderBorder);
        }
      }
      &:not(:first-of-type) {
        button.MuiPickersDay-dayWithMargin:first-of-type {
          border-left: 1px solid var(--color-grayHeaderBorder);
        }
      }
    }
  }
  .MuiYearCalendar-root {
    @extend .custom-scrollbar;
    width: 100%;

    .MuiPickersYear-yearButton {
      font-size: 0.8rem;
      border-radius: 0;

      &.Mui-selected {
        background-color: var(--color-darkgray);
        color: white;
      }
    }
  }

  button.day-limit {
    background-color: var(--color-darkgray);
    color: white;
  }
  button.day-between {
    background-color: var(--color-lightgray2);
    color: var(--color-darkgray);
  }
}

/* TABLE RESULTS*/
.table-results {
  border: 1px solid var(--color-grayHeaderBorder);
  border-radius: 5px 5px 0 0;

  &.no-border {
    border: none;
  }
  &:not(.bottom-border) {
    border-bottom: none;
  }

  .MuiTableRow-root:last-of-type {
    td {
      border-bottom: none;
    }
  }

  &.fixed-layout {
    table {
      table-layout: fixed;
    }
  }
  .base-cell {
    padding: 16px;
  }
  .header-element {
    @extend .base-cell;
    color: #6e6c6b;
    font-weight: 500;
    padding: 16px;
    cursor: pointer;
    text-transform: uppercase;
    white-space: nowrap;

    font-size: var(--fs-12);

    &.with-border {
      border-top: 1px solid var(--color-grayHeaderBorder);
      border-bottom: 1px solid var(--color-grayHeaderBorder);

      &.first {
        border-radius: 5px 0 0 5px;
        border-left: 1px solid var(--color-grayHeaderBorder);
      }
      &.last {
        border-radius: 0 5px 5px 0;
        border-right: 1px solid var(--color-grayHeaderBorder);
      }

      &.noBottomRadius {
        &.first {
          border-radius: 5px 0 0 0;
        }
        &.last {
          border-radius: 0 5px 0 0;
        }
      }
    }
  }
  .cell-element {
    @extend .base-cell;
    color: #151414;
    letter-spacing: 0.01em;

    font-size: var(--fs-14);

    border-top: 1px solid var(--color-grayHeaderBorder);
  }
  thead {
    th {
      color: #6e6c6b;
    }
  }
  td {
    // color: var(--color-darkgray);
    color: #151414;
    letter-spacing: 0.01em;
  }
  .unread-cell {
    padding-inline: 10px 5px;
    padding-block: 5px;
    width: 20px;
  }
  .unread-mark {
    width: 5px;
    height: 5px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: var(--color-fushia);
    margin: auto;
  }
}

/* modal */
label.modal-label,
p.modal-label {
  font-weight: 500;
  // color: var(--color-gray1);
  color: var(--color-gray2);
  font-size: 0.9rem;
  margin-bottom: 5px;

  span.MuiTypography-root {
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
  }
}

/* single alert*/
.alert__target_card_container {
  transform: translateX(calc(-100% * var(--container-shift, 0)));
  display: grid;
  place-items: center top;
  // align-items: flex-start;
  transition: transform 250ms ease-in-out;
}
.alert__target_card {
  grid-row: 1/-1;
  grid-column: 1/-1;
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;

  transform: translateX(calc(100% * var(--card-shift, 0)));
}

/* custom ellipsis*/
.one-line {
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-ellipsis {
  position: relative;
  &::after {
    content: '[...]';
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: inherit;
    color: inherit;
    background: white;
  }
}

/* maps */
.leaflet-container {
  .leaflet-popup-content-wrapper {
    background-color: #444444;
    border-radius: 5px;
  }
  .leaflet-popup-tip-container {
    .leaflet-popup-tip {
      background-color: #444444;
    }
  }
  .leaflet-popup-content {
    max-width: 250px;
    width: max-content !important;
    p {
      margin: 0;
    }
  }
}

.timeline {
  div {
    direction: ltr;
  }
  .timeline-line {
    width: 1px;
    background-color: var(--color-lightgray2);

    &.dashed {
      // background-color: red;
      // background: linear-gradient(to bottom, transparent 50%, #223049 50%), linear-gradient(to bottom, #00b9ff, #59d941);
      background: linear-gradient(to bottom, var(--color-lightgray2) 50%, transparent 50%);
      // background-size: 100% 1rem, 100% 100%;
      background-size: 100% 6px;
    }
  }

  .date {
    font-weight: 500;
    font-size: var(--fs-14);
    color: var(--color-darkgray);
  }
  .text {
    font-size: var(--fs-14);
    color: var(--color-gray2);
    span {
      font-size: inherit;
    }

    &.nowrap {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
