#scrollToTop {
  z-index: 1000;
  right: 0;
  bottom: 0;

  margin-right: 10px;
  margin-bottom: 30px;

  border-radius: 50px;
  background-color: var(--color-lightgray3);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  svg {
    color: var(--color-darkgray);
    rotate: 90deg;
    font-size: inherit;
  }

  transform: translateY((calc(100% + 30px)));
  transition: transform 150ms ease-in-out;

  &.visible {
    transform: translateY(0);
  }
}
